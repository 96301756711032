import './App.css';
import coming_soon from "./images/coming_soon.PNG";
import connect from "./images/connect.png";
import main from "./images/main.png";
import mint from "./images/mint.PNG";
import minus from "./images/minus.png";
import plus from "./images/plus.png";
import withButtons from "./images/withButtons.png";
import withoutButtons from "./images/withoutButtons.png";
import opensea from "./images/opensea.png";
import discord from "./images/discord.png";
import twitter from "./images/twitter.png";
import etherscan from "./images/etherscan.png";

import robinhoodContract from './blockchain/robinhood';

import React, {Component, useState, useEffect} from 'react';
import MediaQuery from 'react-responsive';
import Web3 from "web3";

function App(){

  const mobileSizeLimit = 600;
  const [address, setAddress] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [web3, setWeb3] = useState(null);


  //connect wallet
  const connectWalletHandler = async () => {
    // console.log("Click");
    // alert("Stuff");
    if(typeof window !== "undefined" && typeof window.ethereum !== "undefined"){
      try{
        await window.ethereum.request({method: "eth_requestAccounts"});
        let tempWeb3 = new Web3(window.ethereum);
        setWeb3(tempWeb3);

        // tempWeb3.eth.defaultChain = "rinkeby"
        
        setAddress(tempWeb3.currentProvider.selectedAddress);
      }
      catch(err){
        console.log(err.message);
      }
    }
    else{
      alert("Metamask is not installed");
    }
  };

  //mint function
  const mintHandler = async () => {
    // alert(web3.currentProvider.selectedAddress);
    try{
      const val = web3.utils.toWei('0.04', 'ether') * mintAmount; // 40,000,000 gwei
      const rhContract = robinhoodContract(web3);
      const addr = web3.currentProvider.selectedAddress;
      rhContract.defaultChain = "rinkeby";
      
      await rhContract.methods.mintPublic(addr, mintAmount).send({from: addr, value: val});
    }
    catch(err){
      console.log(err);
    }
  };

  //increment mint function
  const incrementMint = () => {
    if (mintAmount + 1 <= 10){
      setMintAmount(mintAmount + 1);
    }
    console.log(mintAmount)
  };

  //decrement mint function
  const decrementMint = () => {
    if (mintAmount - 1 > 0){
      setMintAmount(mintAmount - 1);
    }
    console.log(mintAmount)
  };

  return (

    <div>
      <div className="App" style={{backgroundColor: "#a7c7e7"}}>
        {/* phone */ } 
        <MediaQuery maxWidth={mobileSizeLimit}>
        {address === "" ? 
          <div>
            <div className = "mobile-padding" font="comic sans">
            </div>
            <div className="App-header-mobile">
              <div style={{
                backgroundImage: `url(${main})`, backgroundSize: "contain", height: '100vh', width: '100vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
              </div>
              <div 
                  onClick={() => connectWalletHandler()}
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "57%",
                      // left: "38%"
                  }}
              >
                <div 
                  style={{backgroundImage: `url(${connect})`, backgroundSize: "contain", height: '17vh', width: '17vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
            </div>
            </div>
            :
            <div>
              <div className = "mobile-padding" font="comic sans">
              </div>
              <div className="App-header-mobile">
                <div style={{
                  backgroundImage: `url(${main})`, backgroundSize: "contain", height: '100vh', width: '100vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
                <div 
                    onClick={() => mintHandler()}
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: "57%",
                        // left: "34%"
                    }}
                >
                  <div 
                    style={{backgroundImage: `url(${mint})`, backgroundSize: "contain", height: '17vh', width: '17vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                  </div>
                </div>
              </div>
              {/* {plus} */}
              <div 
                onClick={() => incrementMint()}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: "71%",
                    left: "60%"
                }}
              >
                <div 
                  style={{backgroundImage: `url(${plus})`, backgroundSize: "contain", height: '4vh', width: '4vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
              {/* {minus} */}
              <div 
                onClick={() => decrementMint()}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: "71%",
                    left: "31%"
                }}
              >
                <div 
                  style={{backgroundImage: `url(${minus})`, backgroundSize: "contain", height: '4vh', width: '4vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
              {/* {numMints} */}
              <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: "black",
                      bottom: '83px',
                      fontFamily: 'Mali',
                      fontSize: '30px',
                      top: "56%",
                      left: "47.5%"
                  }}
              >
                {mintAmount}
              </div>
            </div>
        }
        {/*etherscan*/}
        <div 
          style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: "84%",
              left: "10%"
          }}
          >
            <a 
              href="https://etherscan.io/address/0x0e8dc08fb6f08ad279f5b98c9ead2b07d76c36eb#code" target="_blank" aria-label="etherscan" style={{backgroundImage: `url(${etherscan})`, backgroundSize: "contain", height: '7vh', width: '7vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
            </a>
          </div>
        {/*twitter*/}
        <div 
          style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: "84%",
              left: "32.5%"
          }}
          >
            <a 
              href="https://twitter.com/robinhood_alpha" target="_blank" aria-label="twitter" style={{backgroundImage: `url(${twitter})`, backgroundSize: "contain", height: '7vh', width: '7vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
            </a>
          </div>
          {/* discord */}
          <div 
              style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: "84%",
                  right: "32.5%"
              }}
          >
            <a 
              href="https://discord.gg/aq88UcaMDM" target="_blank" aria-label="discord" style={{backgroundImage: `url(${discord})`, backgroundSize: "contain", height: '7vh', width: '7vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
            </a>
          </div>
          {/* opensea */}
          <div 
            style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: "84%",
                right: "10%"
            }}
          >
              <a 
                href="https://opensea.io/collection/robinhood-pass" target="_blank" aria-label="opensea" style={{backgroundImage: `url(${opensea})`, backgroundSize: "contain", height: '7vh', width: '7vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
              </a>
          </div>
          
        </MediaQuery>
            {/*desktop*/}
        <MediaQuery minWidth = {mobileSizeLimit}>
          {address === "" ? 
            <div className="App-header">
              <div style={{
                backgroundImage: `url(${main})`, backgroundSize: "contain", height: '100vh', width: '100vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
              </div>
              
              <div 
                  onClick={() => connectWalletHandler()}
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "60%",
                      left: "41.5%"
                  }}
              >
                <div 
                  style={{backgroundImage: `url(${connect})`, backgroundSize: "contain", height: '30vh', width: '30vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
            </div>
            :
            <div className="App-header">
              <div style={{
                backgroundImage: `url(${main})`, backgroundSize: "contain", height: '100vh', width: '100vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
              </div>
              <div 
                  onClick={() => mintHandler()}
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "60%",
                      left: "41.5%"
                  }}
              >
                <div 
                  style={{backgroundImage: `url(${mint})`, backgroundSize: "contain", height: '30vh', width: '30vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
              {/* {plus} */}
              <div 
                  onClick={() => incrementMint()}
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "82%",
                      left: "56%"
                  }}
              >
                <div 
                  style={{backgroundImage: `url(${plus})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
              {/* {minus} */}
              <div 
                  onClick={() => decrementMint()}
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "82%",
                      left: "38%"
                  }}
              >
                <div 
                  style={{backgroundImage: `url(${minus})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </div>
              </div>
              {/* {numMints} */}
              <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: "black",
                      bottom: '10.5%',
                      fontFamily: 'Mali',
                      fontSize: '30px'
                  }}
              >
                {mintAmount}
              </div>
            </div>
            }
            {/*twitter*/}
            <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "46%",
                      left: "74%"
                  }}
              >
                <a 
                  href="https://twitter.com/robinhood_alpha" target="_blank" aria-label="twitter" style={{backgroundImage: `url(${twitter})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </a>
            </div>
            {/* discord */}
            <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "58%",
                      left: "74%"
                  }}
              >
                <a 
                  href="https://discord.gg/aq88UcaMDM" target="_blank" aria-label="discord" style={{backgroundImage: `url(${discord})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </a>
            </div>
            {/* opensea */}
            <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "70%",
                      left: "74%"
                  }}
              >
                <a 
                  href="https://opensea.io/collection/robinhood-pass" target="_blank" aria-label="opensea" style={{backgroundImage: `url(${opensea})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
                </a>
            </div>
               {/* etherscan */}
               <div 
                  style={{
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: "82%",
                      left: "74%"
                  }}
              >
            <a 
              href="https://etherscan.io/address/0x0e8dc08fb6f08ad279f5b98c9ead2b07d76c36eb#code" target="_blank" aria-label="etherscan" style={{backgroundImage: `url(${etherscan})`, backgroundSize: "contain", height: '10vh', width: '10vh', margin: "auto", backgroundRepeat: "no-repeat"}}>
            </a>
            </div>
        </MediaQuery>

      </div>
    </div>
  );
}

export default App;
